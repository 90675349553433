import { Button, Input, message, Modal, Select, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ReadyState } from 'react-use-websocket';  

import useRealTime from "SRC/components/realtimetalk/useRealtime";
import useAudioRecorder from "SRC/components/realtimetalk/useAudioRecorder";
import useAudioPlayer from "SRC/components/realtimetalk/useAudioPlayer";
import "./index.less"
import { AudioOutlined, PauseOutlined, RobotOutlined, UserOutlined } from "@ant-design/icons";

interface ConversationTurn {
    userInput: string;
    assistantResponse: string;
}

const voiceOptions = [
    {
        value: "alloy",
        label: "alloy"
    },
    {
        value: "shimmer",
        label: "shimmer"
    },
    {
        value: "echo",
        label: "echo"
    }
]

const voiceModelOptions = [
    {
        value: "gpt-4o-realtime-preview",
        label: "gpt-4o-realtime-preview"
    }
]

export const RealtimeStreamingComponent: React.FC = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [voice, setVoice] = useState("alloy");
    const [voiceModel, setVoiceModel] = useState("gpt-4o-realtime-preview");
    const [conversationTurns, setConversationTurns] = useState<ConversationTurn[]>([]);
    const [currentTurn, setCurrentTurn] = useState<ConversationTurn>({ userInput: '', assistantResponse: '' });
    const [customAssistantEnabled, setCustomAssistantEnabled] = useState(false);
    const [customAssistant, setCustomAssistant] = useState("");
    const [tmpAssistant, setTmpAssistant] = useState("");

    const { startConnection, stopConnection, startSession, addUserAudio, inputAudioBufferClear, readyState } = useRealTime({
        onWebSocketOpen: () => console.log("WebSocket connection opened"),
        onWebSocketClose: () => {
            console.log("WebSocket connection closed")
        },
        onWebSocketError: event => console.error("WebSocket error:", event),
        onReceivedError: message => console.error("error", message),
        onReceivedResponseAudioDelta: message => {
            isRecording && playAudio(message.delta);
        },
        onReceivedInputAudioBufferSpeechStarted: () => {
            stopAudioPlayer();
        },
        onReceivedInputAudioTranscriptionCompleted: message => {
            setCurrentTurn(prev => ({
                ...prev,
                userInput: message.transcript
            }));
        },

        // 接收模型的回复增量，累积构建完整的回复  
        onReceivedResponseAudioTranscriptDelta: message => {
            setCurrentTurn(prev => ({
                ...prev,
                assistantResponse: prev.assistantResponse + message.delta
            }));
        },

        onReceivedResponseDone: () => {
            setConversationTurns(prev => [...prev, currentTurn]);
            setCurrentTurn({ userInput: '', assistantResponse: '' });
        },
    });

    const { reset: resetAudioPlayer, play: playAudio, stop: stopAudioPlayer } = useAudioPlayer();
    const { start: startAudioRecording, stop: stopAudioRecording } = useAudioRecorder({ onAudioRecorded: addUserAudio });
    const [wsReadyState, setWsReadyState] = useState<number>(ReadyState.CLOSED);  
    const [isWaitingForConnection, setIsWaitingForConnection] = useState(false);  

    const historyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (historyRef.current) {
            historyRef.current.scrollTop = historyRef.current.scrollHeight;
        }
    }, [conversationTurns, currentTurn]);

    useEffect(() => {  
        setWsReadyState(readyState);  
    }, [readyState]);

    useEffect(() => {  
        const handleConnectionStateChange = async () => {  
            if (isWaitingForConnection) {  
                if (wsReadyState === ReadyState.OPEN) {  
                    // 连接成功，开始会话和录音  
                    setConversationTurns([]);
                    customAssistantEnabled ? startSession(customAssistant, voice) : startSession("", voice);
                    await startAudioRecording();
                    resetAudioPlayer();
                    setIsRecording(true);
                    setIsWaitingForConnection(false);
                } else if (wsReadyState === ReadyState.CLOSED) {  
                    // 连接失败，提示错误  
                    const modal = Modal.error({
                        title: '操作失败，请稍后再试',
                        content: (
                            <div>
                                <p>很抱歉，您的操作未成功，可能原因包括：</p>
                                <p>1. 对话次数已耗尽</p>
                                <p>2. 服务器繁忙</p>
                                <p>3. 网络问题</p>
                                <p>请稍后再试，或刷新页面重试。如多次失败，请联系管理员。感谢您的理解。</p>
                            </div>
                        ),
                        okButtonProps: { style: { display: 'none' } },
                        className: 'custom-modal',
                        maskClosable: true,
                    });
                    setTimeout(() => {
                        modal.destroy();
                    }, 5000);
                    setIsWaitingForConnection(false);  
                }  
            }  
        };  
      
        handleConnectionStateChange();  
    }, [wsReadyState]); 

    const onToggleListening = async () => {
        if (!isRecording) {
            // setConversationTurns([]);
            startConnection();
            setIsWaitingForConnection(true);
        } else {
            await stopAudioRecording();
            stopAudioPlayer();
            inputAudioBufferClear();
            stopConnection();
            setIsRecording(false);
        }
    };

    const handleVoiceChange = (value: string) => {
        setVoice(value)
    }

    const handleVoiceModelChange = (value: string) => {
        setVoiceModel(value)
    }

    const handleCustomAssistantChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTmpAssistant(event.target.value);
    }

    const handleAssistant = () => {
        setCustomAssistant(tmpAssistant)
        message.info('应用成功！', 1);
    }

    return (
        <div className="realtime-wrapper">
            <div className="left-wrapper">
                <span className="wrapper-title">对话记录</span>
                <div className="audio-wrapper">
                    <div className="history-wrapper" ref={historyRef}>
                        {conversationTurns.map((message, index) => (
                            <div key={index} className="session-wrapper">
                                <div className="audio-user">
                                    <div className="audio-user-input">{message.userInput}</div>
                                    <div className="audio-user-icon"><UserOutlined /></div>
                                </div>
                                <div className="audio-bot">
                                    <div className="audio-bot-icon"><RobotOutlined /></div>
                                    <div className="audio-bot-input">{message.assistantResponse}</div>
                                </div>
                            </div>
                        ))}
                        {(currentTurn.userInput || currentTurn.assistantResponse) && (
                            <div className="session-wrapper">
                                {currentTurn.userInput && (
                                    <div className="audio-user">
                                        <div className="audio-user-input">{currentTurn.userInput}</div>
                                        <div className="audio-user-icon"><UserOutlined /></div>
                                    </div>
                                )}
                                {currentTurn.assistantResponse && (
                                    <div className="audio-bot">
                                        <div className="audio-bot-icon"><RobotOutlined /></div>
                                        <div className="audio-bot-input">{currentTurn.assistantResponse}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="mic-wrapper">
                        <Button
                            onClick={onToggleListening}
                            className="mic-button"
                        >
                            {isRecording ? (
                                <PauseOutlined className="mic-icon" />
                            ) : (
                                <AudioOutlined className="mic-icon"/>
                            )}
                        </Button>
                        <div className="mic-word">
                            {isWaitingForConnection? "正在连接,请稍后...": isRecording?"正在讲话...":"点击按钮，开始对话"}
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-wrapper">
                <div className="user-wrapper">
                    <span className="wrapper-title">对话配置</span>
                    <div className="model-options">
                        <span>当前版本: </span>
                        <Select
                            options={voiceModelOptions}
                            value={voiceModel}
                            style={{ width: 240 }}
                            onChange={handleVoiceModelChange}
                        />
                    </div>
                    <div className="voice-options">
                        <span>对话声音: </span>
                        <Select
                            options={voiceOptions}
                            value={voice}
                            style={{ width: 120 }}
                            onChange={handleVoiceChange}
                        />
                    </div>
                    <div className="custom-assistant-wrapper">
                        自定义助手: <Switch checked={customAssistantEnabled} onChange={setCustomAssistantEnabled} />
                        {customAssistantEnabled && (
                            <div>
                                <Input.TextArea
                                    className="custom-assistant-input"
                                    rows={8}  
                                    showCount  
                                    maxLength={2000}  
                                    value={tmpAssistant}
                                    disabled={isRecording}  
                                    onChange={handleCustomAssistantChange}  
                                />
                                <Button
                                    type="primary"
                                    className="custom-assistant-button"
                                    onClick={handleAssistant}
                                    disabled={!tmpAssistant.trim() || isRecording}
                                >
                                    应用
                                </Button>
                            </div>
                        )}
                    </div>  
                </div>
            </div>
        </div>
    );
}