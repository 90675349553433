import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { globalMessage } from "SRC/components/messagebar";
import { DetailsListDocumentsExample } from "./pages/scenarioManagement/scenarioSetting";
import { Flow } from "./pages/scenarioManagement/configurate";
import { Login } from "./pages/login";
import { Layout } from "./components/layout";
import { AvatarManagement } from "./pages/avatarManagement";
import { AvatarCreate } from "./pages/avatarManagement/create";
import { QASetting } from "./pages/Q&AManagement/QASetting";
import { QADetail } from "./pages/Q&AManagement/QASetting/detail";
import { Trial } from "./pages/trial/index";
import "SRC/global.css";
import { StableDiffusion } from "./pages/stablediffusion";
import { GptTextGeneration } from "./pages/gptTextGeneration";
import { Home } from "./pages/home";
import { MLStudio } from "./pages/mlStudio";
import { SearchDisplay } from "./pages/smartKG/searchDisplay";
import { Database } from "./pages/smartKG/database";
import { useDispatch } from "react-redux";
import { Verify } from "./api/login";
import { setUserInfo, setApps, setNickName } from "SRC/utils/slice";
import { useSelector } from "react-redux";
import Loading from "SRC/components/loading";
import { RealtimeStreamingComponent } from "./pages/realtimeAudio"

export const App: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.location.pathname != "/Login") {
      Verify()
        .then((res: any) => {
          dispatch(setUserInfo(res.phone_number));
          dispatch(setApps(res.atptp_adv_perm));
          dispatch(setNickName(res.nick_name));
        })
        .catch((err) => {
          globalMessage("登录过期，请重新登录", 2);
          navigate("/Login");
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  const apps = useSelector((state: any) => state.apps.value);

  return (
    <div className="App">
      {loading ? (
        <Loading />
      ) : (
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/Index" replace />} />
            <Route path="/Index" element={<Home />} />
            <Route path="*" element={<Navigate to="/Index" replace />} />
            {apps.deva && (
              <Route path="DEVA">
                <Route
                  index
                  element={<Navigate to="ScenarioManagement" replace />}
                />
                <Route path="ScenarioManagement">
                  <Route path="" element={<DetailsListDocumentsExample />} />
                  <Route path="Configurate" element={<Flow />} />
                </Route>
                <Route path="QAManagement">
                  <Route path="" element={<QASetting />} />
                  <Route path="QADetail" element={<QADetail />}>
                    <Route path=":id" element={<QADetail />} />
                  </Route>
                </Route>
                <Route path="VAmanagement">
                  <Route path="" element={<AvatarManagement />} />
                  <Route path="VACreate" element={<AvatarCreate />} />
                </Route>
                <Route path="trial" element={<Trial />} />
              </Route>
            )}
            {apps.dalle && (
              <Route path="/DALLE" element={<StableDiffusion />} />
            )}
            {apps.gpt && <Route path="/GPT" element={<GptTextGeneration />} />}
            {apps.rtaudio && <Route path="/RealtimeAudio" element={<RealtimeStreamingComponent />}/>}
            {apps.azureml && <Route path="MLStudio" element={<MLStudio />} />}
            {apps.smartkg && (
              <Route path="SmartKG">
                <Route
                  index
                  element={<Navigate to="Database" replace />}
                />
                <Route path="Database" element={<Database />} />
                <Route path="SearchDisplay" element={<SearchDisplay />} />
              </Route>
            )}
          </Route>
          <Route index path="Login" element={<Login />} />
        </Routes>
      )}
    </div>
  );
};
