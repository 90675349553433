import * as React from "react";
import { Nav, INavStyles, INavLinkGroup } from "@fluentui/react/lib/Nav";
import { DefaultEffects } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const navStyles: Partial<INavStyles> = {
  root: {
    background: "#F3F2F1",
    width: 228,
    height: "calc(100vh - 48px)",
    display: "block",
    float: "left",
    position: "relative",
    zIndex: 10,
    boxShadow: DefaultEffects.elevation16,
  },
  chevronButton: {
    right: 0,
    left: "none",
  },
  groupContent: {
    marginBottom: 5,
  },
  linkText: {
    color: "#323130",
  },
};

export const Navigator: React.FunctionComponent = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const apps = useSelector((state: any) => state.apps.value);
  const devaapps = {
    name: t("navigator.DEVA"),
    url: "/DEVA",
    icon: "Color",
    onClick: (e: any) => {
      e?.preventDefault();
      navigate("/DEVA/ScenarioManagement");
    },
    isExpanded: true,
    links: [
      {
        key: "SenarioSetting",
        name: t("navigator.ScenarioManagement"),
        url: "/DEVA/ScenarioManagement",
        onClick: (e: any) => {
          e?.preventDefault();
          navigate("/DEVA/ScenarioManagement");
        },
      },
      {
        key: "QASetting",
        name: t("navigator.QAManagement"),
        url: "/DEVA/QAManagement",
        onClick: (e: any) => {
          e?.preventDefault();
          navigate("/DEVA/QAManagement");
        },
      },
      {
        key: "VASetting",
        name: t("navigator.VAManagement"),
        url: "/DEVA/VAManagement",
        onClick: (e: any) => {
          e?.preventDefault();
          navigate("/DEVA/VAManagement");
        },
      },
      {
        name: t("navigator.trial"),
        expandAriaLabel: "Show more Utilities",
        url: "/DEVA/trial",
        onClick: (e: any) => {
          e?.preventDefault();
          navigate("/DEVA/trial");
        },
      },
    ],
  };

  const dalleapps = {
    name: t("navigator.DALLE"),
    url: "/DALLE",
    onClick: (e: any) => {
      e?.preventDefault();
      navigate("/DALLE");
    },
    icon: "ImageCrosshair",
  };

  const gptapps = {
    name: t("navigator.GPT"),
    url: "/GPT",
    onClick: (e: any) => {
      e?.preventDefault();
      navigate("/GPT");
    },
    icon: "ChatBot",
  };
  const realtimeAudio = {
    name: t("navigator.RealtimeAudio"),
    url: "/RealtimeAudio",
    onClick: (e: any) => {
      e?.preventDefault();
      navigate("/RealtimeAudio");
    },
    icon: "ChatBot",
  };

  const MLStudio = {
    name: t("navigator.MLStudio"),
    url: "/MLStudio",
    onClick: (e: any) => {
      e?.preventDefault();
      navigate("/MLStudio");
    },
    icon: "AzureLogo",
  };

  const smartkg = {
    name: t("navigator.SmartKG"),
    url: "/SmartKG",
    onClick: (e: any) => {
      e?.preventDefault();
      navigate("/SmartKG/Database");
    },
    icon: "SplitObject",
    isExpanded: true,
    links: [
      {
        key: "Database",
        name: t("navigator.Database"),
        url: "/SmartKG/Database",
        onClick: (e: any) => {
          e?.preventDefault();
          navigate("/SmartKG/Database");
        },
      },
      {
        key: "SearchDisplay",
        name: t("navigator.SearchDisplay"),
        url: "/SmartKG/SearchDisplay",
        onClick: (e: any) => {
          e?.preventDefault();
          navigate("/SmartKG/SearchDisplay");
        },
      },
    ],
  };

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: t("navigator.Index"),
          url: "/Index",
          onClick: (e) => {
            e?.preventDefault();
            navigate("/Index");
          },
          icon: "home",
        },
      ],
    },
  ];

  if (apps.deva) navLinkGroups[0].links.push(devaapps);
  if (apps.dalle) navLinkGroups[0].links.push(dalleapps);
  if (apps.gpt) navLinkGroups[0].links.push(gptapps);
  if (apps.rtaudio) navLinkGroups[0].links.push(realtimeAudio);
  if (apps.azureml) navLinkGroups[0].links.push(MLStudio);
  if (apps.smartkg) navLinkGroups[0].links.push(smartkg);


  return (
    <React.Fragment>
      <Nav
        styles={navStyles}
        ariaLabel="Virtual Sales Navigator"
        groups={navLinkGroups}
      />
    </React.Fragment>
  );
};
